@use '../util' as *;

// Header Styling
.header {
    background-color: var(--brown);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    
    // Hide Nav at Larger Screen Size
    @include breakpoint(large) {

        .navbar {
            justify-content: flex-end !important;
        }

        .nav-links {
            list-style: none;
            display: flex;
            flex-direction: row !important;
            margin-bottom: 0;
            margin-top: rem(35) !important;

            &__li {
                display: flex;
                align-self: center;
                padding-right: rem(20);
            }

            a {
                text-decoration: none;
                color: var(--coffee);
                font-family: var(--font-body);
                font-size: rem(25);
                text-align: center;
                letter-spacing: rem(2);
                transition-duration: 0.4s;
            }

            a:hover {
                color: var(--white);
            }

        }


        .menu-tog {
            visibility: hidden;
        }

        .wrapper {
            visibility: visible;
            background-color: transparent;
            height: auto;
            top: 0;
            justify-content: space-between;
        }

        .wrapper::after {
            visibility: hidden;
        }
        

    }

    // mobile Screen Size header changes
    @include breakpoint-down(small) {
        background-color: var(--lightbrown);

        .menu-tog span {
            background-color: var(--brown);
        }
          

        .menu-tog.active span {
            background-color: var(--brown);
        }


        .navbar {
            height: rem(55);

            &__img {
                display: none;
            }
        }
    }

    // Nav styles 
    .navbar {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
        margin: rem(15);
        margin-top: 0;
        margin-bottom: 0;

        &__img {
            margin: rem(5);
            width: rem(100);
            height: auto;
        }
        

    }

    .nav-links {
        position: absolute;
        min-width: 30%;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: rem(20);
        margin-bottom: rem(0);

        &__wrapper {

            .wrapper-img {
                width: rem(90);

            }
        }

        &__li {
            display: flex;
            align-self: center;
            padding-right: rem(20);
            margin-bottom: rem(25);
        }

        a {
            text-decoration: none;
            color: var(--cream);
            font-family: var(--font-body);
            font-size: rem(43);
            text-align: center;
            letter-spacing: rem(2);

        }
          
        a{
            color: transparent;
            -webkit-text-stroke: 2px var(--lightbrown);
        }


          @keyframes animate {
            0%,
            100% {
                clip-path: polygon(
                    0% 45%,
                    16% 44%,
                    33% 50%,
                    54% 60%,
                    70% 61%,
                    84% 59%,
                    100% 52%,
                    100% 100%,
                    0% 100%
                );
            }
        
            50% {
                clip-path: polygon(
                    0% 60%,
                    15% 65%,
                    34% 66%,
                    51% 62%,
                    67% 50%,
                    84% 45%,
                    100% 46%,
                    100% 100%,
                    0% 100%
                );
            }
        }
          
    }
    
}

// Nav transitions 
.menu-tog {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 10px;
    justify-content: space-around;
    cursor: pointer;
    transition: 0.5s;
    transform: center;
}
  
.menu-tog span {
    height: 2px;
    background-color: #e3ac6d;
    transform-origin: center;
    transition: 0.2s;
}
  
.menu-tog span:nth-child(1) {
    width: 100%;
}
  
.menu-tog span:nth-child(2) {
    width: 80%;
}
  
.menu-tog.active span {
    background-color: #e3ac6d;
    position: absolute;
    left: 0%;
    width: 100%;
}
  
.menu-tog.active span:nth-child(1) {
    top: 40%;
    transform: rotate(45deg);
}
  
.menu-tog.active span:nth-child(2) {
    bottom: 40%;
    transform: rotate(-45deg);
}


// Need To Work On Wrapper 
.wrapper {
    background-color: var(--coffee);
    position: absolute;
    top: rem(190);
    left: auto;
    right: auto;
    width: 90%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    border-radius: rem(20);
}

.wrapper::after {
    content: "";
    top: -30px;
    right:0;
    position: absolute;
    width: 0;
}

.wrapper.active {
    z-index: 10;
    visibility: visible;
}


// Hero Content
.content {
    background-image: url(/images/Asset13.png);
    background-repeat: no-repeat;
    height: rem(500);
    background-position: center;
    
    // Removing Desktop Styles From 639 > 320
    @include breakpoint-down(small) {
        width: 100%;
        background-image: none;


        &__wrapper {
            display: none;
        }

        &__imgMobile {
            transform: translate(1100%, -2000%);

        }


        .img {
            transform: translate(-1000%, -1000%) !important;
            width: 0;
            height: 0;

        }
    }

    // 425 / 450 
    @include breakpoint-down(xsmall) {
        .mb-img {
            position: relative;
            width: rem(350);
            margin-left: auto;
            margin-right: auto;
            transform: translate(9%, -20%);
        }

    }

    // 320 Styles Mobile
    @include breakpoint-down(xxxsmall) {
        .mb-img {
            position: relative;
            width: rem(270) !important;
            margin-left: auto;
            margin-right: auto;
            transform: translate(9%, -20%) !important;
        }
    }

    // 375 Styles Mobile 
    @include breakpoint-down(xxsmall) {
        .mb-img {
            transform: translate(15%, -20%);
            width: rem(290);
        }
    }


    // small Sized Tablet 
    @include breakpoint(small) {



        &__imgMobile {
            display: none;
        }



        &__img {
            width: rem(375);
            margin-left: auto;
            margin-right: auto;
            transform: translate(-5%, -30%);


            .img {
                width: rem(450);
                height: auto;
            }

            .mb-img {
                display: none;
            }
        }

        &__wrapper {
            margin: 0 !important;
            width: 100%;
            height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            bottom: rem(70);

            .tag {
                font-family: var(--font-head);
                font-size: rem(40);
                color: var(--white);
                margin-bottom: rem(5);
                letter-spacing: rem(1);
            }

            .imgHolder {
                display: flex;
                width: auto;
                align-items: center;

                img {
                    width: rem(20);
                    height: rem(30);
                    margin-bottom: rem(-8);
                    margin-right: rem(5);
                }

                .location {
                    font-family: var(--font-body);
                    font-size: rem(20);
                    color: var(--white);
                    margin-top: rem(25);
                    margin-bottom: rem(10);
                }
            }

            .tele {
                color: var(--cream);
                font-family: var(--font-tele);
                font-size: rem(22);
                margin-top: 0;
            }


        }

    }


    // Laptop / Large Tablet
    @include breakpoint(medium) {
        
    }


    // Desktop
    @include breakpoint(large) {
        background-image: url(/images/coffee-shop-1080.jpg); 
    
    }
}

// Section Break
.section-break {
    background-color: var(--brown);
    

    &__wrapper {
        padding: rem(20);
        display: flex;
        flex-direction: column;
        align-items: center;


        .why {
            color: var(--white);
            text-align: center;
            font-size: rem(18);
            font-family: var(--font-body);
            letter-spacing: rem(2);
            padding: rem(10);
        }

        .vegan {
            color: var(--lightbrown);
            text-align: center;
            font-size: rem(18);
            font-family: var(--font-body);
            letter-spacing: rem(2);
            margin-bottom: rem(15);
        }

        .menu {
            background-color: var(--cream);
            width: rem(200);
            height: rem(50);
            border-radius: rem(10);
            border: 1px solid orange;
            color: var(--brown);
            font-size: rem(25);
            font-weight: bold;
            letter-spacing: rem(1);
            transition-duration: 0.4s;
        }

        .menu:hover {
            background-color: var(--lightbrown);
            color: var(--white);
        }

        
        
    }

    @include breakpoint-down(small) {
        &__wrapper {
            display: none;
        }
    }
}

// About Section 
.about-section {

    // mobile changes 
    @include breakpoint-down(small) {
        .left-content {
            display: none;
            transform: translate(-10000%, -10000%);
        }

        .right-content {
            display: none;
            transform: translate(-10000%, -10000%);
        }
    }
    // Tablet Small Laptop
    @include breakpoint(medium){
        grid-template-columns: 1fr 1fr !important;
    }

    // Desktop
    @include breakpoint(large) {
        grid-template-columns: 1fr 1fr !important;


        .left-content {

            &--header {

                font-size: rem(40) !important;

            }

            &--text {

                font-size: rem(25);
                line-height: rem(45) !important;
                padding: rem(50) !important;
                padding-top: rem(0) !important;
            }

        }


    }

    background-color: var(--mobileBG);
    display: grid;
    grid-template-columns: rem(380) rem(380);
    

    .left-content {
        margin: rem(25);

        .img-wrapper {
            margin: rem(20);
            display: flex;
            justify-content: center;
            align-items: flex-end;

            &__coffee {
                border-radius: 8%;
                box-shadow: -5px -5px rgb(227 172 109 / 40%), -10px -10px rgb(227 172 109 / 30%), -15px -15px rgb(227 172 109 / 20%), -20px -20px rgb(227 172 109 / 10%), -25px -25px rgb(227 172 109 / 5%);
                border-left: rem(40) solid var(--coffee);
                border-top: rem(25) solid var(--coffee);
                border-right: rem(15) solid var(--coffee);
                border-bottom: rem(30) solid var(--coffee);
            }

            &__dec {
                position: absolute;
                transform: translate(-40%, -100%);
            }

            &__org {
                position: absolute;
                padding: 0 0 rem(5) rem(20);
                font-family: var(--font-body);
            }
        }

        &--header {
            color: var(--brown);
            font-family: var(--font-head);
            letter-spacing: rem(3);
            font-size: rem(28);
            text-align: center;
            margin-top: rem(25);
        }

        &--text {
            color: var(--black);
            font-family: var(--font-body);
            margin-top: rem(20);
            text-align: center;
            line-height: rem(25);
            padding: rem(10);
            
        }

    }

    .right-content {
        margin: rem(25);
        margin-left: 0;
        
        .img-wrapper2 {
            margin: rem(20);
            margin-left: auto;
            margin-right: auto;
            width: rem(250);
            display: flex;
            justify-content: center;
            align-items: flex-start;

            &__coffee {
                margin-top: rem(10);
                box-shadow: 5px -5px hsl(32deg 68% 66% / 40%), 10px -10px hsl(32deg 68% 66% / 30%), 15px -15px hsl(32deg 68% 66% / 20%), 20px -20px hsl(32deg 68% 66% / 10%), 25px -25px hsl(32deg 68% 66% / 5%);
                border-radius: 8%;
                border-top: rem(40) solid var(--coffee);
                border-right: rem(20) solid var(--coffee);
                border-bottom: rem(20) solid var(--coffee);
            }


            &__geo {
                position: absolute;
                transform: translate(200%, -20%);
            }


            &__org {
                position: absolute;
                margin-left: 0;
                margin-right: 0;
                padding: rem(22) rem(44) 0 0;
                font-family: var(--font-body);
            }
        }
        
    }
}

// Footer 
.footer-section {
    background-color: var(--brown);
    border-radius: rem(20) rem(20) 0 0;

    //425 - 450 Large Mobile Max Width Small Size
    @include breakpoint-down(xsmall) {
            position: absolute;
            top: rem(690);
            height: 22vh;
            width: 100%;
    
            .social-links {
                grid-template-columns: rem(100) rem(100) !important;
            }
    
    
            img {
                width: rem(40) !important;
                height: auto;
            }
    
            .contact-info {
    
                .addr {
                    font-size: rem(15);
                    margin-top: rem(10);
                }
    
                .email {
                    display: none;
                }
    
                .phone {
                    font-size: rem(15);
                    margin-top: rem(10);
                }
    
            }
    
            .img-holder {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
    
    
    
                .mb-footer {
                    position: absolute;
                    color: var(--coffee);
                    width: rem(140) !important;
                    height: rem(140);
                    transform: translate(-35%, -45%);
                    margin-bottom: rem(10);
                }
    
    
            }
    
    
    
    
    }

    // 320 Mobile
    @include breakpoint-down(xxxsmall) {
        position: absolute;
        top: rem(665) !important;
        height: 25vh !important;
        width: 100%;

        img {
            width: rem(30) !important;
            height: auto;
        }

        .contact-info {

            .addr {
                font-size: rem(16);
            }

            .email {
                display: none;
            }

            .phone {
                display: none;
            }

        }

        .img-holder {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;



            .mb-footer {
                position: absolute;
                color: var(--coffee);
                width: rem(140) !important;
                height: rem(140);
                transform: translate(-35%, -45%);
            }


        }

        .social-links {
            grid-template-columns: rem(100) !important;


            .linkedin {
                display: none;
            }
 
        }


        
    }

    // 375 Mobile
    @include breakpoint-down(xxsmall) {
        position: absolute;
        top: rem(665) !important;
        height: 25vh !important;
        width: 100%;

        img {
            width: rem(30) !important;
            height: auto;
        }

        .contact-info {

            .addr {
                font-size: rem(16);
                margin-top: rem(10);
            }

            .email {
                display: none;
            }

            .phone {
                display: none;
            }

        }

        .img-holder {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;



            .mb-footer {
                position: absolute;
                color: var(--coffee);
                width: rem(140) !important;
                height: rem(140);
                transform: translate(-35%, -45%);
                margin-bottom: rem(10);
            }


        }

        .social-links {
            grid-template-columns: rem(100) !important;


            .linkedin {
                display: none;
            }
 
        }

    }

    // Large Tablet / Laptop
    @include breakpoint(small) {
        
        .img-holder {
            .mb-footer {
                display: none;
            }
        }

    }

    // Desktop
    @include breakpoint(large) {
        .contact-info {
            font-size: rem(26);
        }
    }
    
    .social-links {
        padding: 0;
        width: auto;
        margin-top: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-items: center;
    }

    .contact-info {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: rem(30);
        color: var(--white);
        font-family: var(--font-body);
        letter-spacing: rem(2);
    }

    li {
        margin-top: rem(15);
    }

    img {
        width: rem(130);
        height: auto;
    }
}
