:root {
  --font-head: Pacifico, cursive;
  --font-body: Josefin Sans, sans-serif;
  --font-tele: Merienda One, cursive;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --brown: #603813;
  --lightbrown: #e3ac6d;
  --coffee: #dac09f;
  --cream: #fef8d0;
  --mobileBG: #fff9f3;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}
@media (max-width: 39.9375em) {
  body {
    background-color: var(--coffee);
  }
}

h1,
h2,
h3 {
  margin: 0;
  margin-top: 0;
  line-height: 1.1;
}

p {
  margin: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

@media (max-width: 39.9375em) {
  body {
    height: 100vh;
    max-width: 28.125rem;
  }
}
@media (min-width: 40em) {
  body {
    min-width: 43.75rem;
  }
}

.header {
  background-color: var(--brown);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (min-width: 71.875em) {
  .header .navbar {
    justify-content: flex-end !important;
  }
  .header .nav-links {
    list-style: none;
    display: flex;
    flex-direction: row !important;
    margin-bottom: 0;
    margin-top: 2.1875rem !important;
  }
  .header .nav-links__li {
    display: flex;
    align-self: center;
    padding-right: 1.25rem;
  }
  .header .nav-links a {
    text-decoration: none;
    color: var(--coffee);
    font-family: var(--font-body);
    font-size: 1.5625rem;
    text-align: center;
    letter-spacing: 0.125rem;
    transition-duration: 0.4s;
  }
  .header .nav-links a:hover {
    color: var(--white);
  }
  .header .menu-tog {
    visibility: hidden;
  }
  .header .wrapper {
    visibility: visible;
    background-color: transparent;
    height: auto;
    top: 0;
    justify-content: space-between;
  }
  .header .wrapper::after {
    visibility: hidden;
  }
}
@media (max-width: 39.9375em) {
  .header {
    background-color: var(--lightbrown);
  }
  .header .menu-tog span {
    background-color: var(--brown);
  }
  .header .menu-tog.active span {
    background-color: var(--brown);
  }
  .header .navbar {
    height: 3.4375rem;
  }
  .header .navbar__img {
    display: none;
  }
}
.header .navbar {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0.9375rem;
  margin-top: 0;
  margin-bottom: 0;
}
.header .navbar__img {
  margin: 0.3125rem;
  width: 6.25rem;
  height: auto;
}
.header .nav-links {
  position: absolute;
  min-width: 30%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.25rem;
  margin-bottom: 0rem;
}
.header .nav-links__wrapper .wrapper-img {
  width: 5.625rem;
}
.header .nav-links__li {
  display: flex;
  align-self: center;
  padding-right: 1.25rem;
  margin-bottom: 1.5625rem;
}
.header .nav-links a {
  text-decoration: none;
  color: var(--cream);
  font-family: var(--font-body);
  font-size: 2.6875rem;
  text-align: center;
  letter-spacing: 0.125rem;
}
.header .nav-links a {
  color: transparent;
  -webkit-text-stroke: 2px var(--lightbrown);
}
@keyframes animate {
  0%, 100% {
    clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
  }
}

.menu-tog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 10px;
  justify-content: space-around;
  cursor: pointer;
  transition: 0.5s;
  transform: center;
}

.menu-tog span {
  height: 2px;
  background-color: #e3ac6d;
  transform-origin: center;
  transition: 0.2s;
}

.menu-tog span:nth-child(1) {
  width: 100%;
}

.menu-tog span:nth-child(2) {
  width: 80%;
}

.menu-tog.active span {
  background-color: #e3ac6d;
  position: absolute;
  left: 0%;
  width: 100%;
}

.menu-tog.active span:nth-child(1) {
  top: 40%;
  transform: rotate(45deg);
}

.menu-tog.active span:nth-child(2) {
  bottom: 40%;
  transform: rotate(-45deg);
}

.wrapper {
  background-color: var(--coffee);
  position: absolute;
  top: 11.875rem;
  left: auto;
  right: auto;
  width: 90%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  border-radius: 1.25rem;
}

.wrapper::after {
  content: "";
  top: -30px;
  right: 0;
  position: absolute;
  width: 0;
}

.wrapper.active {
  z-index: 10;
  visibility: visible;
}

.content {
  background-image: url(/images/Asset13.png);
  background-repeat: no-repeat;
  height: 31.25rem;
  background-position: center;
}
@media (max-width: 39.9375em) {
  .content {
    width: 100%;
    background-image: none;
  }
  .content__wrapper {
    display: none;
  }
  .content__imgMobile {
    transform: translate(1100%, -2000%);
  }
  .content .img {
    transform: translate(-1000%, -1000%) !important;
    width: 0;
    height: 0;
  }
}
@media (max-width: 29.66em) {
  .content .mb-img {
    position: relative;
    width: 21.875rem;
    margin-left: auto;
    margin-right: auto;
    transform: translate(9%, -20%);
  }
}
@media (max-width: 20em) {
  .content .mb-img {
    position: relative;
    width: 16.875rem !important;
    margin-left: auto;
    margin-right: auto;
    transform: translate(9%, -20%) !important;
  }
}
@media (max-width: 23.6em) {
  .content .mb-img {
    transform: translate(15%, -20%);
    width: 18.125rem;
  }
}
@media (min-width: 40em) {
  .content__imgMobile {
    display: none;
  }
  .content__img {
    width: 23.4375rem;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-5%, -30%);
  }
  .content__img .img {
    width: 28.125rem;
    height: auto;
  }
  .content__img .mb-img {
    display: none;
  }
  .content__wrapper {
    margin: 0 !important;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 4.375rem;
  }
  .content__wrapper .tag {
    font-family: var(--font-head);
    font-size: 2.5rem;
    color: var(--white);
    margin-bottom: 0.3125rem;
    letter-spacing: 0.0625rem;
  }
  .content__wrapper .imgHolder {
    display: flex;
    width: auto;
    align-items: center;
  }
  .content__wrapper .imgHolder img {
    width: 1.25rem;
    height: 1.875rem;
    margin-bottom: -0.5rem;
    margin-right: 0.3125rem;
  }
  .content__wrapper .imgHolder .location {
    font-family: var(--font-body);
    font-size: 1.25rem;
    color: var(--white);
    margin-top: 1.5625rem;
    margin-bottom: 0.625rem;
  }
  .content__wrapper .tele {
    color: var(--cream);
    font-family: var(--font-tele);
    font-size: 1.375rem;
    margin-top: 0;
  }
}
@media (min-width: 71.875em) {
  .content {
    background-image: url(/images/coffee-shop-1080.jpg);
  }
}

.section-break {
  background-color: var(--brown);
}
.section-break__wrapper {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-break__wrapper .why {
  color: var(--white);
  text-align: center;
  font-size: 1.125rem;
  font-family: var(--font-body);
  letter-spacing: 0.125rem;
  padding: 0.625rem;
}
.section-break__wrapper .vegan {
  color: var(--lightbrown);
  text-align: center;
  font-size: 1.125rem;
  font-family: var(--font-body);
  letter-spacing: 0.125rem;
  margin-bottom: 0.9375rem;
}
.section-break__wrapper .menu {
  background-color: var(--cream);
  width: 12.5rem;
  height: 3.125rem;
  border-radius: 0.625rem;
  border: 1px solid orange;
  color: var(--brown);
  font-size: 1.5625rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  transition-duration: 0.4s;
}
.section-break__wrapper .menu:hover {
  background-color: var(--lightbrown);
  color: var(--white);
}
@media (max-width: 39.9375em) {
  .section-break__wrapper {
    display: none;
  }
}

.about-section {
  background-color: var(--mobileBG);
  display: grid;
  grid-template-columns: 23.75rem 23.75rem;
}
@media (max-width: 39.9375em) {
  .about-section .left-content {
    display: none;
    transform: translate(-10000%, -10000%);
  }
  .about-section .right-content {
    display: none;
    transform: translate(-10000%, -10000%);
  }
}
@media (min-width: 55em) {
  .about-section {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (min-width: 71.875em) {
  .about-section {
    grid-template-columns: 1fr 1fr !important;
  }
  .about-section .left-content--header {
    font-size: 2.5rem !important;
  }
  .about-section .left-content--text {
    font-size: 1.5625rem;
    line-height: 2.8125rem !important;
    padding: 3.125rem !important;
    padding-top: 0rem !important;
  }
}
.about-section .left-content {
  margin: 1.5625rem;
}
.about-section .left-content .img-wrapper {
  margin: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.about-section .left-content .img-wrapper__coffee {
  border-radius: 8%;
  box-shadow: -5px -5px rgba(227, 172, 109, 0.4), -10px -10px rgba(227, 172, 109, 0.3), -15px -15px rgba(227, 172, 109, 0.2), -20px -20px rgba(227, 172, 109, 0.1), -25px -25px rgba(227, 172, 109, 0.05);
  border-left: 2.5rem solid var(--coffee);
  border-top: 1.5625rem solid var(--coffee);
  border-right: 0.9375rem solid var(--coffee);
  border-bottom: 1.875rem solid var(--coffee);
}
.about-section .left-content .img-wrapper__dec {
  position: absolute;
  transform: translate(-40%, -100%);
}
.about-section .left-content .img-wrapper__org {
  position: absolute;
  padding: 0 0 0.3125rem 1.25rem;
  font-family: var(--font-body);
}
.about-section .left-content--header {
  color: var(--brown);
  font-family: var(--font-head);
  letter-spacing: 0.1875rem;
  font-size: 1.75rem;
  text-align: center;
  margin-top: 1.5625rem;
}
.about-section .left-content--text {
  color: var(--black);
  font-family: var(--font-body);
  margin-top: 1.25rem;
  text-align: center;
  line-height: 1.5625rem;
  padding: 0.625rem;
}
.about-section .right-content {
  margin: 1.5625rem;
  margin-left: 0;
}
.about-section .right-content .img-wrapper2 {
  margin: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  width: 15.625rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.about-section .right-content .img-wrapper2__coffee {
  margin-top: 0.625rem;
  box-shadow: 5px -5px rgba(227, 172, 109, 0.4), 10px -10px rgba(227, 172, 109, 0.3), 15px -15px rgba(227, 172, 109, 0.2), 20px -20px rgba(227, 172, 109, 0.1), 25px -25px rgba(227, 172, 109, 0.05);
  border-radius: 8%;
  border-top: 2.5rem solid var(--coffee);
  border-right: 1.25rem solid var(--coffee);
  border-bottom: 1.25rem solid var(--coffee);
}
.about-section .right-content .img-wrapper2__geo {
  position: absolute;
  transform: translate(200%, -20%);
}
.about-section .right-content .img-wrapper2__org {
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  padding: 1.375rem 2.75rem 0 0;
  font-family: var(--font-body);
}

.footer-section {
  background-color: var(--brown);
  border-radius: 1.25rem 1.25rem 0 0;
}
@media (max-width: 29.66em) {
  .footer-section {
    position: absolute;
    top: 43.125rem;
    height: 22vh;
    width: 100%;
  }
  .footer-section .social-links {
    grid-template-columns: 6.25rem 6.25rem !important;
  }
  .footer-section img {
    width: 2.5rem !important;
    height: auto;
  }
  .footer-section .contact-info .addr {
    font-size: 0.9375rem;
    margin-top: 0.625rem;
  }
  .footer-section .contact-info .email {
    display: none;
  }
  .footer-section .contact-info .phone {
    font-size: 0.9375rem;
    margin-top: 0.625rem;
  }
  .footer-section .img-holder {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .footer-section .img-holder .mb-footer {
    position: absolute;
    color: var(--coffee);
    width: 8.75rem !important;
    height: 8.75rem;
    transform: translate(-35%, -45%);
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 20em) {
  .footer-section {
    position: absolute;
    top: 41.5625rem !important;
    height: 25vh !important;
    width: 100%;
  }
  .footer-section img {
    width: 1.875rem !important;
    height: auto;
  }
  .footer-section .contact-info .addr {
    font-size: 1rem;
  }
  .footer-section .contact-info .email {
    display: none;
  }
  .footer-section .contact-info .phone {
    display: none;
  }
  .footer-section .img-holder {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .footer-section .img-holder .mb-footer {
    position: absolute;
    color: var(--coffee);
    width: 8.75rem !important;
    height: 8.75rem;
    transform: translate(-35%, -45%);
  }
  .footer-section .social-links {
    grid-template-columns: 6.25rem !important;
  }
  .footer-section .social-links .linkedin {
    display: none;
  }
}
@media (max-width: 23.6em) {
  .footer-section {
    position: absolute;
    top: 41.5625rem !important;
    height: 25vh !important;
    width: 100%;
  }
  .footer-section img {
    width: 1.875rem !important;
    height: auto;
  }
  .footer-section .contact-info .addr {
    font-size: 1rem;
    margin-top: 0.625rem;
  }
  .footer-section .contact-info .email {
    display: none;
  }
  .footer-section .contact-info .phone {
    display: none;
  }
  .footer-section .img-holder {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .footer-section .img-holder .mb-footer {
    position: absolute;
    color: var(--coffee);
    width: 8.75rem !important;
    height: 8.75rem;
    transform: translate(-35%, -45%);
    margin-bottom: 0.625rem;
  }
  .footer-section .social-links {
    grid-template-columns: 6.25rem !important;
  }
  .footer-section .social-links .linkedin {
    display: none;
  }
}
@media (min-width: 40em) {
  .footer-section .img-holder .mb-footer {
    display: none;
  }
}
@media (min-width: 71.875em) {
  .footer-section .contact-info {
    font-size: 1.625rem;
  }
}
.footer-section .social-links {
  padding: 0;
  width: auto;
  margin-top: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
}
.footer-section .contact-info {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 1.875rem;
  color: var(--white);
  font-family: var(--font-body);
  letter-spacing: 0.125rem;
}
.footer-section li {
  margin-top: 0.9375rem;
}
.footer-section img {
  width: 8.125rem;
  height: auto;
}