@use '../util' as *;

.container {
	padding: 0 rem(24);
	max-width: rem(1110);
	margin: 0 auto;

	@include breakpoint(large) {
		padding: 0;
		transform: translate(0, 0);
	}
}

body {

	@include breakpoint-down(small) {
		height: 100vh;
		max-width: rem(450);
	}
	@include breakpoint(small) {
		min-width: rem(700);
	}

}